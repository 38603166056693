import React from 'react';

const Medical = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="47.361"
    height="47.367"
    viewBox="0 0 47.361 47.367"
  >
    <defs>
      <clipPath>
        <rect width="47.361" height="47.367" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-1765.319 -693.316)">
      <g transform="translate(1765.319 693.316)">
        <g clipPath="url(#clip-path)">
          <path
            d="M23.674,47.367A23.684,23.684,0,1,1,47.362,23.691,23.711,23.711,0,0,1,23.674,47.367m-.014-2.052A21.632,21.632,0,1,0,2.051,23.663,21.679,21.679,0,0,0,23.66,45.316"
            transform="translate(0 0)"
            fill="currentColor"
          />
          <path
            d="M74.257,74.265v.605q0,3.1,0,6.2c0,1.07-.337,1.4-1.412,1.4q-3.745,0-7.49,0c-1.042,0-1.378-.339-1.379-1.389q0-3.1,0-6.2v-.619H63.4q-3.1,0-6.2,0c-1.113,0-1.433-.321-1.433-1.428q0-3.746,0-7.491c0-1.012.347-1.361,1.359-1.363q3.121-.005,6.242,0h.6V63.39q0-3.1,0-6.2c0-1.086.327-1.416,1.4-1.417q3.722,0,7.444,0c1.115,0,1.439.319,1.44,1.422q0,3.075,0,6.15v.633h.561q3.121,0,6.242,0c1.012,0,1.4.388,1.4,1.4q0,3.745,0,7.491c0,1.07-.331,1.395-1.419,1.4q-3.075,0-6.149,0h-.636m-2-16.4H66.043v.529q0,3.122,0,6.243c0,1.053-.364,1.417-1.415,1.418q-3.144,0-6.289,0h-.485V72.2h.529q3.121,0,6.243,0c1.053,0,1.417.364,1.418,1.415q0,3.145,0,6.29v.485h6.144v-.529q0-3.122,0-6.243c0-1.053.364-1.417,1.415-1.418q3.145,0,6.289,0h.558V66.051H73.683c-1.086,0-1.426-.345-1.427-1.441,0-2.234,0-4.468,0-6.749"
            transform="translate(-45.434 -45.44)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Medical;
